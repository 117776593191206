import http from '~/utils/http'

export class ActivityApi {
  // 活动列表
  static activityList(params: NsReqActivityApi.ActivityListParam, opt?: HttpOptions) {
    return http.post<ActivityPageResponse>({
      ...opt,
      url: '/jav/activity/list',
      body: params
    })
  }

  // 根据id 查询单个活动配置，活动详情
  static activeDetail(params: IdParam, opt?: HttpOptions) {
    return http.post<NsResActivityApi.ActivityPageResponse>({
      ...opt,
      url: '/jav/activity/detail',
      body: params
    })
  }
}
